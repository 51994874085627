.Maindiv {
    /* background-color: rgb(240, 230, 230); */

}

.mainabout {

    /* background-color: yellow; */
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutupper {
    /* background-color: aqua; */
    width: 100%;
    height: 450px;
    background-size: 100%;
    background-image: url("https://previews.123rf.com/images/denispc/denispc1303/denispc130300130/18756741-group-of-cartoon-business-people.jpg");
}

.aboutupper1 {
    position: absolute;
    background-color: #000;
    width: 100%;

    height: 450px;
    justify-content: center;
    display: flex;
    align-items: center;
    opacity: 0.5;
}

.aboutupper h1 {
    margin: 10px;
    font-size: 3rem;
}

.aboutupper2 {
    position: relative;

    width: 100%;
    color: #fff;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    top: 0;
    z-index: 1;
}

.ourteam {
    /* background-color: #F6F7FB; */
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    margin-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    height: 800px;
}

.color1 {
    background-color: #999;
    width: 100%;
    height: 50px;
}

.color2 {
    background: rgb(100, 149, 202);
    background: linear-gradient(90deg, rgb(127, 169, 212) 0%, #4A60B0 100%);

    margin-top: 10px;
    width: 100%;
    height: 300px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.boss {
    /* background-color: violet; */
    width: 200px;
    height: 270px;
    color: #fff;
    position: relative;
    top: -110px;

}

.userimagess {
    width: 100%;
    height: 150px;
    border-radius: 50%;
}

.boss h1,
h3 {
    margin: 0;
}

.employers {
    /* background-color: #000; */
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    height: 500px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 60px;
    top: -50px;
    /* z-index: 2; */
}

.employersdetails {
    width: 150px;
    height: auto;
    /* background-color: #0271EB; */
    color: #0271EB;
}

.employersdetails h2,
h4 {
    margin: 0;
}



.outstory {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    /* background: url(/themes/custom/creatrixcampus/images/masonry_bg.png) no-repeat;
       background-position: 79% center;
       background-size: 30%,20%; */
}



.ourstory2 {
    /* background-color: aqua; */
    width: 80%;
    height: 500px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 200px;
}

.ourstoryp {
    /* background-color: aqua; */
    height: auto;
    width: 60%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: baseline;


}

.ourstory h1 {
    box-shadow: 0 6px 1px -3px #0271EB;
    width: fit-content;
    height: fit-content;
}

.ourstoryp h1 {
    background: rgb(100, 149, 202);
    background: linear-gradient(90deg, rgb(127, 169, 212) 0%, #4A60B0 100%);
    color: white;
    width: 150px;
    display: flex;
    padding: 5px;
}


.outstoryleft {
    /* background-color: #0271EB; */
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 10px;
}


.aboutusrightdiv {
    background-color: #fff;
    height: 45%;
    width: 45%;
    border: 0;
    box-shadow: 0 0 15px rgba(166, 161, 240, 0.13);
    border-radius: 30px 30px 0 30px;
    opacity: 1;
    padding: 5px;
    justify-content: center;
    align-items: baseline;
    text-align: left;
    display: flex;
    flex-direction: column;

}

.aboutusrightdiv p {
    color: #84899b;
}

.valueaboutus {
    /* background-color: #F9FAFA; */
    background: url("https://www.creatrixcampus.com/themes/custom/creatrixcampus/images/pattern_shape.png") no-repeat 140px 350px;

    width: 100%;
    height: fit-content;
    margin-top: 50px;
    /* padding-left: 8%; */
    padding-bottom: 50px;
    /* padding-right: 8%; */
    padding-top: 3px;
    display: flex;
    justify-content: center;
}


.valueaboutush1 {
    /* margin-left: 10%; */
    color: #0271EB;
    text-align: left;
    width: fit-content;
    box-shadow: 0 6px 1px -3px #0271EB;
    /* margin-top: "300px"; */

}

.aboutboxes {
    display: flex;
}

.aboutboxes2 {
    margin-top: 150px;
    width: 400px;
    /* background-color: #35369B; */
    text-align: justify;
    padding: 10px;
}

.valueaboutus p {
    color: #535a74;

}

.h1 {
    box-shadow: 0 6px 1px -3px #0271EB;
    width: fit-content;
    height: fit-content;
    color: #0271EB;
}












.forempoloyers {
    background: #F6F6F6;
    /* background: linear-gradient(90deg, rgba(100, 149, 202, 1) 0%, rgba(54, 56, 156, 1) 100%); */
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40%;
}

.for {
    background: rgb(100, 149, 202);
    background: transparent linear-gradient(90deg, #35369B 0%, #6597CB 100%) 0% 0% no-repeat;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: white;

    /* gap: 40%; */
}

.for p {
    width: 90%;

    text-align: center;
}

p {
    text-align: justify;
}

.for h1 {
    color: white;
    box-shadow: 0 0 0 0;
}



.forempoloyers h1 {
    box-shadow: 0 0 0px 0px;
}

.forempoloyers button {
    background-color: white;
    width: 200px;
    box-shadow: 0 0 3px #0271EB;
    height: 40px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    color: #0271EB;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

.homecomments {
    /* background-color: ; */
    width: 100%;
    height: 500px;
}


.innaws {
    background: rgb(171, 203, 235);
    background: linear-gradient(90deg, rgb(188, 204, 223) 0%, rgb(187, 187, 226) 100%);

    width: 100%;
    height: 500px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.innaws h5 {
    margin-bottom: 50px;
}

.carouselitem2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 54%;

    margin: 1px;

}



.ourclients {
    /* background-color: violet; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.carousel {
    /* background-color: yellow; */
    width: 100%;
    /* justify-content:'center';
     align-items:'center'; */
}

.carouselitem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 90%;

    margin: 1px;

}

.newsletter {

    /* background-color: yellow; */
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.nawsletterpra {
    width: 45%;
    /* background-color: violet; */
}

.newslettersub {
    width: 45%;
    /* background-color: tomato; */
}

.newslettersub input {
    width: 65%;
    box-shadow: 0 0 3px #0271EB;
    height: 30px;
    border-radius: 100px;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    background: rgb(216, 229, 243);
    background: linear-gradient(90deg, rgb(188, 206, 224) 0%, rrgb(111, 112, 139)100%);

    padding: 0.5rem 2rem;
}

.newslettersub button {
    background: rgb(100, 149, 202);
    background: linear-gradient(90deg, rgba(100, 149, 202, 1) 0%, rgba(54, 56, 156, 1) 100%);
    width: 20%;
    left: 3%;
    position: relative;
    box-shadow: 0 0 3px #0271EB;
    height: 50px;
    border-radius: 100px;
    margin-top: 10px;
    font-size: 1rem;
    color: white;
    /* padding: 0.5rem 2rem; */
    cursor: pointer;
}

.newslettersub button:hover {
    background: white;
    color: #0271EB;

}

@media screen and (max-width:860px) {

    .aboutupper1 {
        height: 200px;
    }

    .aboutupper {
        height: 200px;
    }

    .mainabout {
        background-color: #35369B;
        height: fit-content;
        padding: 0;
    }

    .aboutupper2 {
        /* background-color: #0508c0; */
        height: 200px;
        padding: 0;
    }

    .ourstory2 {
        /* background-color: #35369B; */
        flex-direction: column;
        padding: 0;
        width: 90%;
        height: fit-content;
        margin-top: 0;
        padding: 20px;
        /* height: fit-content; */
    }

    .ourstory2 img {
        width: 100%;
        height: auto;
    }

    .ourstoryp {
        padding: 0;
        width: 100%;
        height: fit-content;
        /* background-color: yellow; */
        flex-direction: column;

    }

    .outstoryleft {
        width: 100%;
        margin-top: 20px;
        /* background-color: #35369B; */
        height: 500px;
    }

    .valueaboutush1 {
        /* background-color: #35369B; */
        margin-top: 0px;
    }

    .valueaboutus {
        /* background-color: #419b35; */
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        height: fit-content;

    }

    .aboutboxes {
        display: none;
    }

    .aboutboxes2 {
        margin-top: 0px;
        width: 90%;
        padding: 18px;
        /* background-color: #35369B; */
    }

    .aboutboxes2s {
        display: none;

    }

    .ourteam {
        /* background-color: #35369B; */
        height: 2400px;
    }

    .color2 {
        height: 100px;
    }

    .employers {
        /* background-color: #35369B; */
        height: fit-content;
    }


    /* .valueaboutush1{
        margin-top: 1000px;
        background-color: #35369B;
    } */

    .forempoloyers {
        flex-direction: column;
        gap: 0;
    }

    .newsletter{
        /* background-color: #35369B; */
        flex-direction: column;

    }

    .nawsletterpra{
        width: 90%;
    }
    .newslettersub{
        width: 90%;
    }
    .newslettersub button{
        width: 50%;
    }
}