.mainpro {

    /* background-color: rgb(240, 230, 230); */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: 60px;
    padding-top: 0;



}

.hovar:hover {
    cursor: pointer;
}


.productmainpro {
    width: 100%;
    height: fit-content;
    /* background-color: aqua; */
    display: flex;
    margin-bottom: 60px;

}

.productmainleft {
    /* background-color: blue; */
    width: 25%;
    padding: 20px;
}

.productmainright {
    /* background-color: rgb(8, 168, 62); */
    width: 75%;

    padding: 20px;
    display: flex;
    /* justify-content:  space-around; */
    /* align-items:first baseline; */
    flex-wrap: wrap;
    height: fit-content;
    /* overflow-y: scroll; */
}


.productdetails {
    display: flex;
    width: 100%;
    height: fit-content;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    /* background-color: yellow; */
}

.productdetails2 {
    background-color: aqua;
    width: 100%;

    /* display: flex; */
    padding-top: 20px;
    gap: 50px;
    align-items: center;
}

.prodetailsright {
    width: 48%;
    height: fit-content;
    /* background-color: #044ff3; */
}



.data-plans-table {
    border-collapse: collapse;
    width: 100%;
    color: rgb(100, 100, 100);
}

.data-plans-table th,
.data-plans-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}


.readmore:hover {
    cursor: pointer;
    text-decoration: underline;
}





.chectoutform {
    /* background-color: white; */
    width: 100%;
    height: fit-content;

}

.chectoutform label {
    color: gray;
    font-size: 1.5em;
}


.chectoutforminput {
    background-color: white;
    width: 100%;
    padding-left: 10px;
    height: 30px;
    padding-right: 0;
    border-radius: 5px;
    border: 1px solid #c0bcbc;
}


.chectoutforminput2 {
    background-color: #044ff3;
    width: 100.8%;

    height: 40px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    border: 1px solid gray;
}

.chectoutforminput2:hover {
    background-color: white;
    color: gray;
    cursor: pointer;

}

.chectoutforminput2:active {
    scale: 0.99;
}




.procarddd {

    width: 100%;
    height: fit-content;
    display: flex;
    /* background-color: #044ff3; */
    flex-direction: row;
    border: 1px solid gray;
    padding: 5px;
    margin-bottom: 20px;

}

.procarddd2 {

    width: 100%;
    height: fit-content;
    display: flex;
    /* background-color: #044ff3; */
    flex-direction: row;
    border: 1px solid gray;
    padding: 5px;
    margin-bottom: 20px;

}






.procarddd2:hover {
    cursor: pointer;
    scale: 1.009;
    box-shadow: 0 0 10px 1px;
}




.subprocard {
    width: 55%;
    display: flex;
    /* background-color: tomato; */
}

.subprocardImg {
    width: 500px;
    height: 200px;

}

.subprocarddescrib2 {
    text-align: justify;
    width: 36%;
}

.subprocarddescrib {
    text-align: justify;
}


.subprocardfeature {
    width: 18%;
    /* background-color: yellow; */
    text-align: left;
    height: fit-content;

}

.subprocardfeature h5:hover {
    text-decoration: underline #044ff3;
    cursor: pointer;

}



.subprocardprice {
    text-align: left;
    width: 13%;
    /* background-color: rgb(132, 0, 255); */


}


.subprocardbutton {
    width: 13%;


}

.subprocardbutton button {

    width: 100%;
    height: 40px;
    font-weight: bold;
    background-color: #044ff3;
    border-radius: 3px;
    border: 1px solid #020c22;
    color: white;

}


.subprocardbutton button:hover {
    background-color: white;
    color: #044ff3;
    cursor: pointer;
}

.subprocardbutton button:active {
    scale: 0.99;
}





.routercardbox {

    /* background-color: yellow; */
    padding: 20px;
    height: fit-content;
    /* border: 1px solid gray; */
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}


.routercard {
    border: 1px solid gray;
    padding: 20px;
   max-width: 100%;
    width: 300px;
    height: fit-content;
    text-align: left;
    border-radius: 10px;
    /* background-color: #044ff3; */
}

.routercard:hover {
    cursor: pointer;
    scale: 1.009;
    box-shadow: 0 0 10px 1px #044ff3; 
}

.routercard:active {
    cursor: pointer;
    scale: 1.009;
    box-shadow: 0 0 10px 5px;
}





.removeicon {
    /* background-color: #044ff3; */
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    position: relative;
    color: red;
    top: -120px;
    z-index: 99;
    left: 90%;
}

.removeicon:hover {
    cursor: pointer;
    scale: 1.09;
}









.divtext {
    width: 95%;
    display: flex;
    justify-content: flex-start;
    padding-left: 17px;
    text-align: justify;
    color: gray;
    /* background-color: yellow; */
}

.imagessss{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}


.mainpadding{
    padding: 60px;
}









.dshopmaindiv{
/* background-color: #044ff3; */
width: 100%;
height: fit-content;
display: flex;
gap: 10px;
justify-content: space-evenly;

flex-wrap: wrap;
padding: 20px;

}






@media screen and (max-width:860px) {
   
    .mainpadding{
        padding: 1px !important;
        width: 90%;
    }
    
    .mifimain {
        padding: 5px !important;
       
    }


    .removeicon {
        left: 60%;
        top: -165px;
   
    }
    .mainpro {

        padding: 10px;
        padding-top: 0;

        .chectoutform label {
            color: gray;
            font-size: 0.7em;
        }

    }




    .productmainpro {
        width: 102%;
        height: fit-content;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

    }

    .productmainleft {
        /* background-color: blue; */
        width: 100%;
        padding: 0px;
    }

    .productmainright {

        width: 100%;

        padding: 0px;
        display: flex;
        /* justify-content:  space-around; */
        /* align-items:first baseline; */
        flex-wrap: wrap;
        height: fit-content;
        /* overflow-y: scroll; */
    }

    .productdetails {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        gap: 20px;
        /* background-color: yellow; */
    }

    .prodetailsright {
        width: 98%;
        padding-right: 2%;
        /* background-color: #044ff3; */
    }

    .procarddd2 {
        /* background-color: yellow; */
        width: 96%;
        flex-direction: column;
    }

    .subprocard {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .subprocarddescrib2 {
        width: 100%;
    }

    .subprocardfeature {
        width: 100%;
    }

    .productdetails3 {
        padding: 10px;
        width: 95%;
    }

    .imagessss{
        width: 100%;
        gap: 2px;
    }

    .chectoutform{
        font-size: 0.5em;
        padding-left: 10px
    }
    .procarddd {
        flex-direction: column;
    }
    .subprocardbutton {
        width: 100%;
    }

    .subprocardImg {
        width: 100%;
    }


    .dshopmaindiv{
       
        padding: 5px !important;
        /* width: 100%; */
        }
}