.indexmaindiv {
    /* background-color: rgb(240, 230, 230); */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: 60px;
    padding-top: 20px;
}


.indexupercard {
    /* opacity: 0; */
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 50px;
    /* transition: opacity 0.5s ease */
    animation: myAnimation 3s ease-in-out;
}

@keyframes myAnimation {
    0% {
        opacity: 0;
        gap: 500px;
    }
    
    100% {
        opacity: 1;
        gap: 0px;
    }
  }





.indexupercardleftright {
    background-size: cover;
    width: 45%;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding-left: 40px;
    color: white;
    box-shadow: 0 0px 10px 2px grey;

}

.indexupercardleftright h3:hover {
    cursor: pointer;
    scale: 1.05;
}


.indexnewarrivalluper {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.indexnewarrivallcards {
    /* background-color: rgb(182, 111, 24); */
    width: 23%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    cursor: pointer;
}

.indexnewarrivallcards img {
    border-radius: 10px;
    width: 100%;
    height: 250px;
}

.indexnewarrivallcards:hover {
    scale: 1.1;
    box-shadow: 0 0 1px 10px;
}




.indexBigSavingZoneuper {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    /* align-items: center; */
    animation: myAnimation 3s ease-in-out;
}

@keyframes myAnimation {
    0% {
        opacity: 0;
        gap: 500px;
    }
    
    100% {
        opacity: 1;
        gap: 0px;
    }
  }


.indexBigSavingZonecards {
    background-color: rgb(182, 111, 24);
    width: 32%;
    height: 280px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding-left: 10px;
    color: white;
    border-radius: 10px;
}




.indexbannerauper {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 10px;
    /* align-items: center; */
    animation: myAnimation 3s ease-in-out;
}

@keyframes myAnimation {
    0% {
        opacity: 1;
        gap: 500px;
    }
    
    100% {
        opacity: 1;
        gap: 0px;
    }
  }


.indexbannercards {
    /* background-color: rgb(182, 111, 24); */
    width: 45%;
    height: 300px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: last baseline;
    padding-right: 50px;
    /* color: white; */
    border-radius: 10px;
}






.indexflashdeals {
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
    width: 100%;
    height: 300px;

    margin-bottom: 80px;
}

.indexflashdealsdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 50%;
    padding-left: 50px;
    background-size: 'cover';
    height: 100%;
    color: white;
    padding-bottom: 20px;

}

.indexflashdealsdiv p {
    width: '40%';
    padding-right: 40px;
    /* background-color: yellow; */
}



.indexflashdealsdiv2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 50%;
    padding-left: 50px;
    background-size: 'cover';
    height: 100%;
    color: white;
    padding-bottom: 20px;
}




.indexflashdealsdivbutton {
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 0px 50px 0px 50px;
    cursor: pointer;
}

.indexflashdealsdivbutton:hover {
    background-color: gray;
    color: white;
}


.indexoffersuper {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
}

.indexofferscards {
    /* background-color: rgb(182, 111, 24); */
    width: 23%;
    height: fit-content;
}

.indexofferscards button {
    border: 1px solid red;
    background-color: white;
}

.indexofferscards:hover {
    scale: 1.05;
    cursor: pointer;
    box-shadow: 0 0 10px 1px;
    border-radius: 5px;
}

.indexofferscards img {
    border-radius: 10px;
    width: 100%;
    height: 300px;
}

.indexbanner {
    /* background-color: violet; */
    width: 100%;
    height: fit-content;
    margin-top: 60px;
    margin-bottom: 60px;
}


.indextimeoffersuper {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: 400px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}

.indextimeofferscards {
    /* background-color: rgb(182, 111, 24); */
    width: 23%;
    height: 400px;
}

.indextimeofferscards:hover {
    scale: 1.05;
    cursor: pointer;
    box-shadow: 0 0 1px 1px;

}

.indextimeofferscards img {
    border-radius: 10px;
    width: 100%;
    height: 300px;
}



.indexfeedback {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
}



.indexfeedbackcard {
    /* background-color: rgb(182, 111, 24); */
    width: 32%;
    height: fit-content;
}




.feedback-card {
    border: 5px solid #ccc;
    padding: 10px;
    width: 95%;
    text-align: center;
}

.feedback-card h2 {
    margin: 0;
}

.feedback-card img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* margin-bottom: 10px; */
}

.rating {
    color: orange;
    /* margin: 10px 0; */
    margin: 0;
}

.star {
    font-size: 20px;
}

p {
    margin: 0;
}








.indexpartners {
    display: flex;
    /* background-color: aquamarine; */
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 60px;
}

.indexpartners img {
    width: 200px;
}

.indexpartnerscard {
    background-color: rgb(182, 111, 24);
    width: 20%;
    height: 200px;
    background-size: cover;
}




.divwithimages {
    /* background-color: yellowgreen; */
    width: 100%;
    display: flex;
}

/* .divwithimages:nth-child(1) {
    background-color: yellowgreen;
    width: 100%;
    display: flex;
} */


.divwithimages img {
    /* background-color: yellowgreen; */
    width: 50%;

}











@media screen and (max-width:860px) {

    .divwithimages {
        /* background-color: yellowgreen; */
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .divwithimages img {
        /* background-color: yellowgreen; */
        width: 100%;

    }


    .indexpartners img {
        width: 100px;
    }

    h1 {
        font-size: 1rem;
    }

    h2 {
        font-size: 0.9rem;
    }

    h3 {
        font-size: 0.8rem;
    }

    h4 {
        font-size: 0.7rem;
    }

    h5 {
        font-size: 0.6rem;
    }

    .indexmaindiv {
        padding: 30px;
    }

    .indexupercard {
        flex-direction: column;

    }

    .indexupercardleftright {

        width: 100%;
       
        height: 200px;
        margin-bottom: 20px;
        opacity: 1;
        /* transform: translateY(20px); */
       
        /* transition: opacity 0.5s ease, transform 0.5s ease; */
    }
   








    .indexnewarrivallcards {
        /* height: 100px; */
        width: 140px;

    }

    .indexBigSavingZoneuper {
        flex-direction: column;
    }


    .indexBigSavingZonecards {
        width: 100%;
        margin-top: 20px;
        height: fit-content;
        padding: 10px 10px;
    }

    .indexbannerauper {
        flex-direction: column;

    }


    .indexbannercards {
        width: 100%;
        background-color: aqua;
        padding-right: 10px;
        height: fit-content;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 10px;
        font-size: 0.7em;
    }

    .indexflashdealsdiv {
        background-color: aqua;
        width: 100% !important;
        padding: 10px;
        height: fit-content;
        font-size: 0.7em;
        border-radius: 5px;
        margin: 0;

    }

    .indexflashdeals {


        height: fit-content;


    }

    .indexflashdealsdiv p {
        /* background-color: aqua; */
        width: 100%;

    }


    .indexflashdealsdiv2 {
        display: none;
    }

    .indexoffersuper {
        flex-direction: column;
    }

    .indexofferscards {
        /* background-color: rgb(182, 111, 24); */
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        text-align: left;
    }


    .indexofferscards img {
        border-radius: 10px;
        width: 100px;
        height: 100px;
    }


    .indextimeoffersuper {
        flex-direction: column;
    }

    .indextimeofferscards {
        /* background-color: rgb(182, 111, 24); */
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        text-align: left;
    }


    .iindextimeofferscards img {
        border-radius: 10px;
        width: 100%;
        /* height: 50px; */
    }


    .indexfeedback {
        flex-direction: column;
    }

    .indexfeedbackcard {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }


}