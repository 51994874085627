.mifimain {
    /* background-color: aqua; */
    padding: 60px;
    padding-top: 0;
    text-align: left;
}

.mifimifiselectplanbagmain {
    width: 100%;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.39); */
    position: fixed;
    top: 0;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}



.mifiselectplanbag {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.863);
    position: fixed;
    top: 0;
    z-index: 200;
}

.mifiselectplanbag2 td:hover {
    cursor: pointer;
    font-weight: bold;
}

.buttoonn {
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    height: fit-content;
}

.buttoonn button {
    /* background-color: yellow; */
    width: 40%;
    height: 30px;


}

.buttoonn button:hover {
    /* background-color: yellow; */

    cursor: pointer;

}

.buttoonn button:active {
    scale: 0.99;

}


.buttoonn button:nth-child(1) {
    border: 1px solid red;
    color: red;


}

.buttoonn button:nth-child(2) {

    background-color: #0587EF;
    color: white;

}

.buttoonn button:nth-child(2):hover {

    color: #0587EF;
    background-color: white;
    border: 1px solid #0587EF;
}


.mifiselectplanbag2 {
    padding: 30px 60px;
    width: 60%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
    background-color: white;
    position: relative;
    /* top: 20vh;
    left: 20%; */
    z-index: 300;

    max-height: 500px;
    overflow-y: scroll;
}

.selected-cell {
    background-color: #0587EF;
    color: white;
}



@media screen and (max-width:860px) {

    .mifiselectplanbag2 {
        /* background-color: #0587EF; */
        padding: 5px;
        width: 90%;
    }
}








/* routers css */

.selectrouterbox {
    /* background-color: yellow; */
    width: 100%;
    margin-bottom: 50px;
    display: flex;
}


.selectrouterboxleft {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border:1px solid #0587EF;
    color: #0587EF;
}


.selectrouterboxright {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0587EF;
    height: 40px;
    color: white;
    border:1px solid #0587EF;
}

.selectrouterboxright::before {
    background-color: #0587EF;
    width: 40px;
    height: 40px;
    content: "";
    position: relative;
    top: 20px;
    transform: rotate(45deg);
}

.selectrouterboxleft:hover{
    cursor: pointer;
}