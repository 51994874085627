.maincontactus {
    /* background-color: rgb(240, 230, 230); */
    width: 100%;
    height: auto;
    padding-top: 100px;
    /* padding-bottom: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 50px;
}

.contactusform {
    background: rgb(0, 37, 77);
    background: transparent linear-gradient(90deg, #0374EC 0%, #10CFFA 100%) 0% 0% no-repeat;

    width: 50%;
    height: 600px;
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 50px;
}


.contactusform h1 {
    margin: 0;
    padding: 0;
}



.contactusdetails h5 {
    margin-bottom: 70px;
    padding: 0;
}

.contactusdetails h4 {
    margin: 0;
    padding: 0;
}

.iconsforcontact {
    /* background: white; */
    /* background-color: #4A60B0; */
    color: white;
    width: 50px;
    height: 50px;
    /* margin-top: 70px; */
}


.contactusdetails {
    background: rgb(100, 149, 202);
    background: transparent linear-gradient(90deg, #0374EC 0%, #10CFFA 100%) 0% 0% no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    width: 15%;
    height: 600px;
    border-radius: 20px;

}


.formforcontact {
    /* background-color: aqua; */
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    width: 90%;
    justify-content: left;
    align-items: baseline;
    text-decoration: dashed;
}

.inputcontact {
    width: 93%;
    box-shadow: 0 0 3px #4A60B0;
    height: 30px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    background: rgb(216, 229, 243);
    background: linear-gradient(90deg, rgb(188, 206, 224) 0%, rrgb(111, 112, 139)100%);

    padding: 0.5rem 2rem;
}

.divforinput2 {

    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
    align-items: baseline;
    text-decoration: dashed;
}

.divforinput {
    display: flex;
    gap: 10px;

    width: 110%;
}

.divforinput2 input {
    width: 69%;
}

.inputcontact2 {
    width: 93%;
    box-shadow: 0 0 3px #4A60B0;
    height: 200px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    background: rgb(216, 229, 243);
    background: linear-gradient(90deg, rgb(188, 206, 224) 0%, rrgb(111, 112, 139)100%);

    padding: 0.5rem 2rem;
}


.buttoncontact {
    background: white;

    width: 50%;
    left: 3%;
    position: relative;
    box-shadow: 0 0 3px #4A60B0;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    color: rgb(100, 149, 202);

    cursor: pointer;
}


.locationcontactus {
    /* box-shadow: 0px 0px 10px 5px; */
    width: 100%;
    height: 400px;
    /* background-color: #4A60B0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: bottom;

}

.maincontactus {
    /* background-color: aqua; */
    width: 100%;
    height: auto;
    padding-top: 100px;
    /* padding-bottom: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.contactusform {
    background: rgb(100, 149, 202);
    background: transparent linear-gradient(90deg, #0374EC 0%, #10CFFA 100%) 0% 0% no-repeat;

    width: 50%;
    height: 600px;
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 50px;
}


.contactusform h1 {
    margin: 0;
    padding: 0;
}



.contactusdetails h5 {
    margin-bottom: 70px;
    padding: 0;
}

.contactusdetails h4 {
    margin: 0;
    padding: 0;
}

.iconsforcontact {
    /* background: white; */
    /* background-color: #4A60B0; */
    color: white;
    width: 50px;
    height: 50px;
    /* margin-top: 70px; */
}


.contactusdetails {
    background: rgb(100, 149, 202);
    background: transparent linear-gradient(90deg, #10CBFA 0%, #0378EC 100%) 0% 0% no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    width: 15%;
    height: 600px;
    border-radius: 20px;

}


.formforcontact {
    /* background-color: aqua; */
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    width: 90%;
    justify-content: left;
    align-items: baseline;
    text-decoration: dashed;
}

.inputcontact {
    width: 93%;
    box-shadow: 0 0 3px #4A60B0;
    height: 30px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    background: rgb(216, 229, 243);
    background: linear-gradient(90deg, rgb(188, 206, 224) 0%, rrgb(111, 112, 139)100%);

    padding: 0.5rem 2rem;
}

.divforinput2 {

    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
    align-items: baseline;
    text-decoration: dashed;
}

.divforinput {
    display: flex;
    gap: 10px;

    width: 110%;
}

.divforinput2 input {
    width: 69%;
}

.inputcontact2 {
    width: 93%;
    box-shadow: 0 0 3px #4A60B0;
    height: 200px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    background: rgb(216, 229, 243);
    background: linear-gradient(90deg, rgb(188, 206, 224) 0%, rrgb(111, 112, 139)100%);

    padding: 0.5rem 2rem;
}


.buttoncontact {
    background: white;

    width: 50%;
    left: 3%;
    position: relative;
    box-shadow: 0 0 3px #4A60B0;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1rem;
    color: rgb(100, 149, 202);

    cursor: pointer;
}


.locationcontactus {
    /* box-shadow: 0px 0px 10px 5px; */
    width: 100%;
    height: 400px;
    /* background-color: #4A60B0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: bottom;

}

.jobsdiv {
    /* background-color: aqua; */
    padding: 20px;
    /* overflow-y: auto; */
    max-height: fit-content;
    margin-top: 20px;
    border: 1px dotted #0583EE;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
}

.joblist {
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}

.joblist p {
    margin: 0;

}


 .formalbtn {
    background-color: #0583ee;
    border: 1px solid #053157;
    color: #fff;
    padding: 5px 5%;
}

.formalbtn:hover {
    background-color: #fff;
    border: 1px solid #053157;
    box-shadow: 0 0 3px 1px;
    color: #0583ee;
    cursor: pointer;
}


@media screen and (max-width:860px) {
    .maincontactus {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;


    }



    .contactusdetails {
        width: 100%;
        padding: 0px;
    }

    .divforinput {
        flex-direction: column;
        width: 100%;
        /* width: 100%; */
        padding: 0;
        /* background-color: aqua; */
    }

    .contactusform {
        width: 100%;
        height: fit-content;
        padding: 20px 0px;
        border-radius: 5px;

    }


    .formforcontact {
        width: 90%;
        padding-left: 20px;
        /* padding: 4%; */
    }

    .divforinput2 {
        /* background-color: yellow; */
        width: 100%;
    }

    .divforinput2 input {
        width: 80%;
    }

    .inputcontact2 {
        width: 80%;

    }

}