.faqmaindiv{
    width: 100%;
    height: fit-content;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.uperwaladin{
    width: 50%;

}

@media screen and (max-width:860px) {
    .faqmaindiv {
        
       flex-direction: column;
    }
    .uperwaladin{
        width: 80%;
    
    }
}