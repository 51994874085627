.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}








.navbar {
  position: absolute;
  top: 0%;
  z-index: 90;
  width: 100%;
  height: 60px;
  /* display: flex;
    justify-content: center; */
  /* align-items: center; */
  /* background-color: #920f0f; */
  color: white;
}




.nav-list {
  /* height: 100px; */
  top: 0%;
  /* list-style: none; */
  display: flex;
  /* margin-top: -10px; */
  /* background-color: yellow; */
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px;
}

.nav-item {
  margin: 0 10px;
}

.nav-item a {
  text-decoration: teal;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  /* background-color: #0587EF; */
}

.nav-item a:hover {
  /* text-decoration: underline #0587EF; */
  border-bottom: 3px solid #0587EF;

}


.icon {
  font-size: 18px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.nav-list .active {
  /* background-color: #555; */
  border-bottom: 3px solid #0587EF;
}




.nabdropdown {
  background-color: rgba(133, 131, 131, 0.637);
  width: 90%;
  height: fit-content;
  box-shadow: 0px 1000px 0px 1000px rgba(82, 80, 80, 0.74);
  position: absolute;
  top: 40px;
  left: 0;
  display: none;
}


.nabdropdown2 {
  width: 100%;
  justify-content: space-evenly;
  height: fit-content;
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 50px;

}

.insidedrop {
  text-align: left;
}

.insidedrop li:hover {
  cursor: pointer;
  text-decoration: underline #0587EF;
}

.droplink:hover {
  cursor: pointer;
  text-decoration: underline #0587EF;

}

.droplink {

  margin-bottom: 30px;
}



.navbar2 {
  position: sticky;
  top: 0;

  width: 100%;
  background-color: #ffffff;
  z-index: 100;
  font-weight: 500;


}

.nav-list2 {
  /* background-color: aqua; */
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 50px;
  border: 1px solid #0587EF;
  /* position: sticky;
    top: 0; */

}

.nav-item2 {
  margin: 0 0px;
}

.nav-item2 a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  width: fit-content;
  /* border: 1px solid transparent; */
  /* border-radius: 5px; */
  /* border-left:1px solid #0587EF; */
  /* border-right:1px solid #0587EF ; */
  transition: background-color 0.3s;
}

@media screen and (max-width:1300px) {
  .nav-item2 a {
    font-size: 0.7em;
  }
}
@media screen and (max-width:1100px) {
  .nav-item2 a {
    font-size: 0.6em;
  }
}
@media screen and (max-width:900px) {
  .nav-item2 a {
    font-size: 0.5em;
  }
}

.nav-item2 a:hover {
  background-color: #0587EF;
  color: #fff;


}

.nav-item2:hover .nabdropdown {
  display: flex;
}

.nabdropdown:hover {
  display: none;

}


/* Active menu item style */
.nav-item2 a.active {
  background-color: #0587EF;
  color: white;
}





.footer {
  display: flex;
  justify-content: baseline;

  background-color: #0587EF;
  color: white;
  height: fit-content;
  padding: 50px;
  flex-wrap: wrap;
}

.subfooter {
  margin-right: 60px;
  /* background-color:yellow; */
  color: white;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;

}

.footerlinks {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footerlinks:hover {
  text-decoration: underline;
}

.nav-list2mob {
  display: none;
}

.nav-listmob {
  display: none;

}



.cartcout {

  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -13px;
  right: 10px;
}















@media screen and (max-width:860px) {







  .navbar {
    background-color: #fff;
  }









  .nav-listmob {
    height: 100px;
    top: 0;
    /* list-style: none; */
    display: flex;
    /* margin-top: -10px; */
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

  }

  .mobnav {
    background-color: #fff;
    position: fixed;
    top: 110px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 78vh;
    align-items: baseline;
    /* justify-content: center; */
    /* overflow: scroll; */
    overflow-y: scroll;
    padding: 50px 10px;
  }

  .nav-list {
    display: none;

  }

  .mobbbbnav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
  }

  .nav-list2 {
    display: none;
  }




  .nav-list2mob {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 0.5px solid #0587EF;
    font-size: 0.6em;
  }


  .nav-item2 a {
    text-decoration: none;
    color: #0587EF;
    padding: 5px 10px;
    width: fit-content;
    /* border: 1px solid transparent; */
    /* border-radius: 5px; */
    border-left: 1px solid #0587EF;
    border-right: 1px solid #0587EF;
    transition: background-color 0.3s;
  }


}