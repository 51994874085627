.maincart {
    padding: 60px;
}

.productdtailscart {
    /* margin-top: 50px; */
    display: flex;
    /* background-color: yellow; */
    justify-content: space-between;
    /* align-items: center; */
    border: 1px solid gray;
    padding: 5px;
    width: 100%;
}

.checkoutbutton {
    background-color: #0587EF;

    height: 30px;
    font-weight: bolder;
    margin-top: 40px;
    color: white;
    border: 1px solid #16578b;
    margin-bottom: 10px;



}

.checkoutbutton:hover {
    background-color: white;

}

.procartimag{
    width: 200px;
    height: 200px;
}

.divproooo{
    display: block;
}
.redcartdataplan{
    width: 100%;
    background-color: red;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    color: white;
    padding: 5px;
}

@media screen and (max-width:860px) {
    .maincart {
        padding: 10px;
    }
    .divproooo{
        display: flex;
    /* background-color: aqua; */
    flex-direction: column;
    }

    .redcartdataplan{
        width: 96%;
    }
    .procartimag{
        width: 150px;
        height: 150px;
    }

    .productdtailscart {
        flex-direction: column;
        width: 95%;
        /* background-color: #0587EF; */

    }

    
}